/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { getStringLimit, showToast } from "utils/common";
import { forwardRef } from "react";
import BannerService from "services/banners";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { nanoid } from "nanoid";
import { InputNumber, Panel, MultiSelect } from "primereact";
import VUserDropdown from "components/v-user";
import CategoryFilterDetails from "components/v-filter";
import { COMMON_STATUS } from "utils/enum";
import { RadioButton } from "primereact/radiobutton";
import branchShopService from "services/branches-shop";
import { InputSwitch } from "primereact/inputswitch";
import Service from "services/categories";
import { CATEGORY_GROUP_KEY } from "utils/constant";
import debounce from "lodash.debounce";
import { Calendar } from "primereact/calendar";

const emptyData = {
  user_id: null,
  is_facebook: false,
  is_youtube: false,
  is_images: false,
  facebook: "",
  youtube: "",
  positions: [],
  images: [],
  start_date: new Date(),
  end_date: new Date(),
  countdown: "",
  order: 0,
  video: "",
  name: "",
  action_href: [],
  category_filter_id: null,
  description: "",
  status: COMMON_STATUS.ENABLE,
  adv_users: [],
  resolution: {
    x: 0,
    y: 0,
  },
  marquee: {
    fileUrl: "",
    content: "",
    background_color: "",
    text_color: "",
  },
  link_video: "",
  link_href_video: "",
  time_start_show: 0,
  time_end_show: 0,
  enable_close: false,
  time_enable_close: 0,
  banner_positions: [],
  menus: [],
};

const positionButtonBanner = [
  {
    name: "Top Right",
    value: "top-right",
  },
  {
    name: "Top Left",
    value: "top-left",
  },
  {
    name: "Bottom Right",
    value: "bottom-right",
  },
  {
    name: "Bottom Left",
    value: "bottom-left",
  },
  {
    name: "Center",
    value: "center",
  },
];

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    bannerPositions,
    categoryFilters,
    setBannerPositions,
    getCategoryFilters,
    menus,
    positions,
  } = props;
  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);
  const [advUsers, setAdvUsers] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: data.start_date ? new Date(data.start_date) : new Date(),
        end_date: data.end_date ? new Date(data.end_date) : new Date(),
        countdown: new Date(data.countdown),
      });
  }, [data]);

  useEffect(() => {
    if (details.user_id) {
      handleGetADVUsers();
    } else {
      setAdvUsers([]);
    }
  }, [details.user_id]);

  const handleGetADVUsers = async () => {
    try {
      const users = await branchShopService.getByUser({
        params: { user_id: details.user_id },
      });
      if (users?.length > 0) {
        setAdvUsers(users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const onChangeMarquee = (name: string, value: string) => {
    setDetails((state) => ({
      ...state,
      marquee: {
        ...state.marquee,
        [name]: value,
      },
    }));
  };

  // const onChangeResolution = (name: string, value: number) => {
  //   setDetails((state) => ({
  //     ...state,
  //     resolution: {
  //       ...state.resolution,
  //       [name]: value,
  //     },
  //   }));
  // };

  const onChangeActionButton = (id, name, value) => {
    setDetails((state) => ({
      ...state,
      action_href: (state.action_href || [])?.map((item) =>
        item.id === id ? { ...item, [name]: value } : item
      ),
    }));
  };

  const handleSubActionButton = (id) => {
    setDetails((state) => ({
      ...state,
      action_href: (state.action_href || [])?.filter((item) => !item.id),
    }));
  };

  const onInputCheckChange = (val, name) => {
    if (name === "positions") {
      const positionUrl = bannerPositions
        ?.filter((item) => val?.includes(item?.key) && item?.url_active)
        ?.map((el) => el?.url_active);
      const data = [...val, ...positionUrl];
      setDetails({
        ...details,
        [name]: data,
      });
    } else {
      setDetails({
        ...details,
        [name]: val,
      });
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await BannerService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await BannerService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const moveUp = (index: number) => {
    const _images = details.images;
    const _before = details.images[index - 1];
    const _after = details.images[index];
    _images[index] = _before;
    _images[index - 1] = _after;
    setDetails((state) => ({ ...state, images: [..._images] }));
  };

  const moveDown = (index: number) => {
    const _images = details.images;
    const _before = details.images[index + 1];
    const _after = details.images[index];
    _images[index] = _before;
    _images[index + 1] = _after;
    setDetails((state) => ({ ...state, images: [..._images] }));
  };

  const handleSubIndex = (index) => {
    setDetails((state) => ({
      ...state,
      images: state.images.filter((i) => i.id !== index),
    }));
  };

  const changeLinkItemImages = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, link: e.target.value } : item
      ),
    }));
  };

  const changeTimeoutItemImages = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, timeout: e.value * 1000 } : item
      ),
    }));
  };

  const handleImageChange = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      images: state.images.map((item) =>
        item.id === index ? { ...item, src: e } : item
      ),
    }));
  };

  const handleAdd = () => {
    const added = [...details.images, { id: nanoid(), src: "", link: "" }];
    setDetails((state) => ({ ...state, images: added }));
  };

  const handleAddAction = () => {
    const added = [
      ...details.action_href,
      {
        id: nanoid(),
        button_name: "",
        text_color: "",
        background_color: "",
        link: "",
      },
    ];
    setDetails((state) => ({ ...state, action_href: added }));
  };

  const onFilterPosition = async (e) => {
    if (e?.filter) {
      const res: any = await Service.search({
        query: {
          group: CATEGORY_GROUP_KEY.BANNER_PAGE,
          search: e?.filter,
          page: 1,
          limit: 10,
        },
      });
      if (res && res?.docs) {
        const newData = res?.docs?.filter(
          (el) => !bannerPositions.find((item) => item?._id === el?._id)
        );
        setBannerPositions((bannerPositions || []).concat(newData));
      }
    }
  };

  const onSearchFilterPosition = debounce(onFilterPosition, 700);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="adv_user">Owner</label>
              <VUserDropdown
                value={details.user_id}
                setValue={(e) => onInputCheckChange(e, "user_id")}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="adv_user">ADV users</label>
              <MultiSelect
                value={details.adv_users}
                options={advUsers.map((item) => ({
                  label: item.full_name,
                  value: item._id,
                }))}
                filter
                showClear
                optionLabel="label"
                optionValue="value"
                placeholder="Select adv users"
                onChange={(e) => {
                  onChange("adv_users", e.value);
                }}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="name">Status</label>
              <Dropdown
                defaultValue={details.status}
                value={details.status}
                options={[
                  {
                    label: "ENABLE",
                    value: "enable",
                  },
                  {
                    label: "DISABLE",
                    value: "disable",
                  },
                ]}
                onChange={(e) => onChange("status", e.value)}
                optionLabel="label"
                placeholder="Select status"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                className="w-full"
                value={details.name}
                onChange={(e) => onChange("name", e.target.value)}
              />
            </div>

            <div className="field col-12">
              <label htmlFor="status">Banner Position By Key</label>
              <MultiSelect
                value={bannerPositions
                  ?.filter((el) => details?.positions?.includes(el.key))
                  .map((item) => item?.key)}
                options={bannerPositions.map((item) => ({
                  label: item.name,
                  value: item.key,
                }))}
                filter
                showClear
                optionLabel="label"
                optionValue="value"
                placeholder="Select banner position"
                onFilter={(e) => onSearchFilterPosition(e)}
                onChange={(e) => {
                  onInputCheckChange(e.value, "positions");
                }}
              />
            </div>

            <div className="field col-12">
              <label htmlFor="status">Menus</label>
              <MultiSelect
                value={menus
                  ?.filter((el) => details?.menus?.includes(el._id))
                  .map((item) => item?._id)}
                options={menus.map((item) => ({
                  label: getStringLimit(
                    `${item.name} ${item.url ? `(${item.url})` : ""}`,
                    50
                  ),
                  value: item._id,
                }))}
                filter
                showClear
                optionLabel="label"
                optionValue="value"
                placeholder="Menus"
                // onFilter={(e) => onSearchFilterPosition(e)}
                onChange={(e) => {
                  onInputCheckChange(e.value, "menus");
                }}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="order">Order</label>
              <InputNumber
                value={details.order}
                onChange={(e) => onChange("order", e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="timeStart">Position</label>
              <MultiSelect
                defaultValue={details.banner_positions}
                value={details.banner_positions}
                options={positions}
                onChange={(e) => onChange("banner_positions", e.value)}
                optionLabel="label"
                placeholder="Select position"
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="start-date">Start Date</label>
              <Calendar
                id="start-date"
                showIcon
                showTime
                placeholder="Start date"
                value={details.start_date}
                onChange={(e) => onChange("start_date", e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="end-date">End Date</label>
              <Calendar
                id="end-date"
                showIcon
                showTime
                placeholder="End date"
                value={details.end_date}
                onChange={(e) => onChange("end_date", e.value)}
              />
            </div>
            {/* <div className="field col-12 md:col-6">
              <label htmlFor="resolution">Resolution X</label>
              <InputNumber
                value={details.resolution.x}
                onChange={(e) => onChangeResolution("x", e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="resolution">Resolution Y</label>
              <InputNumber
                value={details.resolution.y}
                onChange={(e) => onChangeResolution("y", e.value)}
              />
            </div> */}
          </div>

          <Panel
            header="Attachment/Video/Image/Link"
            toggleable
            className="my-2"
            collapsed={true}
          >
            <div className="grid">
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="name"
                    className="w-full"
                    value={details.facebook}
                    onChange={(e) => onChange("facebook", e.target.value)}
                  />
                  <label htmlFor="id">Facebook</label>
                </span>
              </div>

              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="name"
                    className="w-full"
                    value={details.youtube}
                    onChange={(e) => onChange("youtube", e.target.value)}
                  />
                  <label htmlFor="id">Youtube</label>
                </span>
              </div>
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="name"
                    className="w-full"
                    value={details.link_video}
                    onChange={(e) => onChange("link_video", e.target.value)}
                  />
                  <label htmlFor="id">Link Video</label>
                </span>
              </div>
            </div>
            <div className="field col-12">
              <span className="p-float-label">
                <InputText
                  id="description"
                  className="w-full"
                  value={details.description}
                  onChange={(e) => onChange("description", e.target.value)}
                />
                <label htmlFor="id">Description</label>
              </span>
            </div>
            <div className="field col-12">
              <h4>Marquee</h4>
              <div>
                <VUpload
                  urlFile={details.marquee?.fileUrl}
                  setUrlFile={(url) => {
                    onChangeMarquee("fileUrl", url);
                  }}
                />
                <div className="flex align-items-center">
                  <InputText
                    className="mt-3"
                    value={details.marquee?.content}
                    onChange={(e) => onChangeMarquee("content", e.target.value)}
                  />
                  <div className="ml-5">
                    <label htmlFor="">Background color</label>
                    <input
                      type="color"
                      className="mt-2"
                      value={details.marquee?.background_color}
                      onChange={(e) =>
                        onChangeMarquee("background_color", e.target.value)
                      }
                    />
                  </div>
                  <div className="ml-5">
                    <label htmlFor="">Text color</label>
                    <input
                      type="color"
                      className="mt-2"
                      value={details.marquee?.text_color}
                      onChange={(e) =>
                        onChangeMarquee("text_color", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h4>Video</h4>
              <VUpload
                fileType="video"
                urlFile={details.video}
                setUrlFile={(url) => onChange("video", url)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="name"
                  className="w-full"
                  value={details.link_href_video}
                  onChange={(e) => onChange("link_href_video", e.target.value)}
                />
                <label htmlFor="id">Link href</label>
              </span>
            </div>
            <div className="pb-3">
              <h4>Images</h4>
            </div>
            {details.images.map((item, index) => {
              return (
                <div className="grid" key={item.id}>
                  <div className="col-12">
                    <VUpload
                      urlFile={item.src}
                      setUrlFile={(e) => handleImageChange(e, item.id)}
                    />
                  </div>

                  <div className="col-12 lg:col-5">
                    <span className="p-float-label">
                      <InputText
                        id="name"
                        className="w-full"
                        value={item.link}
                        onChange={(e) => changeLinkItemImages(e, item.id)}
                      />
                      <label htmlFor="link">Link</label>
                    </span>
                  </div>
                  <div className="col-12 lg:col-5">
                    <span className="p-float-label">
                      <InputNumber
                        id="timeout"
                        className="w-full"
                        value={(item.timeout || 0) / 1000}
                        onChange={(e) => changeTimeoutItemImages(e, item.id)}
                      />
                      <label htmlFor="timeout">Timeout (seconds)</label>
                    </span>
                  </div>

                  <div className="flex field col-12 md:col-2 md:justify-content-evenly">
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger"
                      aria-label="Cancel"
                      onClick={(_) => handleSubIndex(item.id)}
                    />
                    {index === 0 ? (
                      <Button
                        icon="bx bx-chevron-down"
                        className="p-button-rounded p-button-warning"
                        aria-label="Cancel"
                        onClick={(_) => moveDown(index)}
                      />
                    ) : index === details.images.length - 1 ? (
                      <Button
                        icon="bx bx-chevron-up"
                        className="p-button-rounded p-button-primary"
                        aria-label="Cancel"
                        onClick={(_) => moveUp(index)}
                      />
                    ) : (
                      <>
                        <Button
                          icon="bx bx-chevron-up"
                          className="p-button-rounded p-button-primary"
                          aria-label="Cancel"
                          onClick={(_) => moveUp(index)}
                        />
                        <Button
                          icon="bx bx-chevron-down"
                          className="p-button-rounded p-button-warning"
                          aria-label="Cancel"
                          onClick={(_) => moveDown(index)}
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              onClick={handleAdd}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3"
              aria-label="Search"
            />
          </Panel>

          <Panel
            header=" Action Button"
            toggleable
            className="my-2"
            collapsed={false}
          >
            {details.action_href?.map((el) => (
              <div key={el.id} className="field col-12 md:col-6">
                <div className="field-radiobutton mr-2">
                  <RadioButton
                    inputId="text"
                    name="text"
                    value="text"
                    onChange={(e) =>
                      onChangeActionButton(el.id, "type", e.value)
                    }
                    checked={!el.type || el.type === "text"}
                  />
                  <label htmlFor="text">Text</label>
                </div>
                <div className="field-radiobutton mr-5">
                  <RadioButton
                    inputId="image"
                    name="image"
                    value="image"
                    onChange={(e) =>
                      onChangeActionButton(el.id, "type", e.value)
                    }
                    checked={el.type === "image"}
                  />
                  <label htmlFor="image">Image</label>
                </div>
                {el.type === "image" ? (
                  <div className="col-12">
                    <VUpload
                      urlFile={el.button_image}
                      setUrlFile={(e) =>
                        onChangeActionButton(el.id, "button_image", e)
                      }
                    />
                  </div>
                ) : (
                  <>
                    <div className="field col-12">
                      <label htmlFor="name">Button Name</label>
                      <InputText
                        id="button_name"
                        value={el.button_name}
                        onChange={(e) =>
                          onChangeActionButton(
                            el.id,
                            "button_name",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="field col-12">
                      <label htmlFor="name">Background color</label>
                      <div className="flex">
                        <InputText
                          id="background_color"
                          value={el.background_color}
                          onChange={(e) =>
                            onChangeActionButton(
                              el.id,
                              "background_color",
                              e.target.value
                            )
                          }
                        />
                        <input
                          className="ml-1"
                          type="color"
                          id="head"
                          name="head"
                          value={el.background_color}
                          onChange={(e) =>
                            onChangeActionButton(
                              el.id,
                              "background_color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="field col-12">
                      <label htmlFor="name">Text color</label>
                      <div className="flex">
                        <InputText
                          id="text_color"
                          value={el.text_color}
                          onChange={(e) =>
                            onChangeActionButton(
                              el.id,
                              "text_color",
                              e.target.value
                            )
                          }
                        />
                        <input
                          className="ml-1"
                          type="color"
                          id="head"
                          name="head"
                          value={el.text_color}
                          onChange={(e) =>
                            onChangeActionButton(
                              el.id,
                              "text_color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="field col-12">
                  <label htmlFor="name">Link</label>
                  <InputText
                    id="link"
                    value={el.link}
                    onChange={(e) =>
                      onChangeActionButton(el.id, "link", e.target.value)
                    }
                  />
                </div>
                <div className="field col-12">
                  <label htmlFor="status">Position Button Banner</label>
                  <Dropdown
                    filter
                    showClear
                    value={el.position}
                    options={positionButtonBanner.map((item) => ({
                      label: item.name,
                      value: item.value,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select position"
                    onChange={(e) =>
                      onChangeActionButton(el.id, "position", e.value)
                    }
                  />
                </div>
                <Button
                  icon="pi pi-times"
                  className="p-button-rounded p-button-danger"
                  aria-label="Cancel"
                  onClick={(_) => handleSubActionButton(el.id)}
                />
              </div>
            ))}
            <Button
              onClick={handleAddAction}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3 block"
              aria-label="Search"
            />
          </Panel>

          <Panel
            header="Popup setting"
            toggleable
            className="my-2"
            collapsed={true}
          >
            <div className="grid">
              <div className="field col-12 md:col-6">
                <label htmlFor="timeStart">Time start show</label>
                <InputNumber
                  id="timeStartShow"
                  value={details.time_start_show}
                  onChange={(e) => onChange("time_start_show", e.value)}
                  required
                  autoFocus
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="timeEnd">Time end show</label>
                <InputNumber
                  id="timeEndShow"
                  value={details.time_end_show}
                  onChange={(e) => onChange("time_end_show", e.value)}
                  required
                  autoFocus
                />
              </div>
              <div className="field col-6">
                <label htmlFor="enableClose">Enable close</label>
                <br />
                <InputSwitch
                  checked={details.enable_close}
                  onChange={(e) => onChange("enable_close", e.value)}
                />
                <br />
                {details.enable_close && (
                  <>
                    <label htmlFor="timeEnableClose">{`Time enable close (s)`}</label>
                    <InputNumber
                      id="timeEnableClose"
                      value={details.time_enable_close}
                      onChange={(e) => onChange("time_enable_close", e.value)}
                      required
                      autoFocus
                    />
                  </>
                )}
              </div>
            </div>
          </Panel>

          <div className="grid">
            <div className="field col-12">
              <label htmlFor="status">Filter</label>
              <Dropdown
                filter
                showClear
                value={details.category_filter_id}
                options={categoryFilters.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                optionLabel="label"
                optionValue="value"
                placeholder="Select filter"
                onChange={(e) =>
                  onInputCheckChange(e.value, "category_filter_id")
                }
              />
            </div>
          </div>
          {!details.category_filter_id && (
            <div className="field col-12">
              <Panel
                header="Filter"
                toggleable
                className="my-2"
                collapsed={false}
              >
                <div className="grid">
                  <div className="field col-12">
                    <CategoryFilterDetails
                      ref={refDetailCategory}
                      onChangeOptions={onChange}
                    />
                  </div>
                </div>
              </Panel>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
