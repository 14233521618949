import { useState } from "react";
import "./style.scss";
import WebModulesIframe from "components/iframe-web-module";

const TemplateManagerModule = () => {
  const [loading, setLoading] = useState(false);

  const getIframeUrl = (key: string) => {
    const pathKey = {
      templates: "/image-editor",
    };
    if (pathKey[key]) {
      return `${process.env.REACT_APP_WEB_MODULE_URL}${
        pathKey[key] || "/"
      }?origin=${window.origin}&vdb_token=${localStorage.getItem(
        "token_auth"
      )}`;
    }
    return null;
  };

  return (
    <div className="templates-wrapper">
      <WebModulesIframe
        url={getIframeUrl("templates")}
        className="iframe"
        title="web modules templates"
        onLoad={() => setLoading(false)}
        loading={loading}
      />
    </div>
  );
};

export default TemplateManagerModule;
