/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useImperativeHandle, useContext } from "react";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { getCategories, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { USER_GENDER } from "utils/enum";
import { MasterContext } from "contexts/MasterContext";
import { TreeSelect } from "primereact/treeselect";
import VLocation from "components/v-location";
import VUserDropdown from "components/v-user";

const CreateMulti = (props, ref) => {
  const { reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    prefix: "",
    location: {},
    career_id: null,
    nation_id: null,
    religion_id: null,
    family_id: null,
    category_field_id: null,
    category_id: null,
    gender: "MALE",
    referred_by: null,
  };
  const [details, setDetails] = useState(emptyData);
  const { careers, nations, religions, families, categoryFields, categories } =
    useContext(MasterContext);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      await UserService.createMultiUserByType({
        body: {
          ...details,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-12">
        <label htmlFor="description">Prefix</label>
        <InputText
          id="full_name"
          value={details.prefix}
          onChange={(e) => onChange("prefix", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-12">
        <VUserDropdown
          value={details.referred_by}
          setValue={(v) => onChange("referred_by", v)}
          className="w-full"
          optionValue="refer_code"
        />
      </div>

      <div className="field col-12 md:col-12">
        <VLocation
          address={details.location}
          setAddress={(a) => onChange("location", a)}
        />
      </div>

      <div className="field col-12 md:col-12">
        <Panel header="Filter" toggleable className="my-2" collapsed={true}>
          <div className="grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Gender</label>
              <Dropdown
                value={details.gender}
                options={Object.keys(USER_GENDER).map((k) => ({
                  label: k,
                  value: USER_GENDER[k],
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("gender", e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Family</label>
              <Dropdown
                filter
                value={details.family_id}
                options={families.map((c) => ({
                  label: c.name,
                  value: c._id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("family_id", e.value)}
                showClear
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Career</label>
              <Dropdown
                filter
                value={details.career_id}
                options={careers.map((c) => ({
                  label: c.name,
                  value: c._id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("career_id", e.value)}
                showClear
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Nation</label>
              <Dropdown
                value={details.nation_id}
                filter
                options={nations.map((c) => ({
                  label: c.name,
                  value: c._id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("nation_id", e.value)}
                showClear
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Category Fields</label>
              <Dropdown
                filter
                value={details.category_field_id}
                options={categoryFields.map((c) => ({
                  label: c.name,
                  value: c._id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("category_field_id", e.value)}
                showClear
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Religion</label>
              <Dropdown
                filter
                value={details.religion_id}
                options={religions.map((c) => ({
                  label: c.name,
                  value: c._id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("religion_id", e.value)}
                showClear
              />
            </div>
            <div className="field col-12 ">
              <label htmlFor="name">Category Product</label>
              <TreeSelect
                value={details.category_id}
                filter
                options={getCategories(categories)}
                onChange={(e) => onChange("category_id", e.value)}
                className="w-full"
                placeholder="Select Parent"
              ></TreeSelect>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default forwardRef(CreateMulti);
