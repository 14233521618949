import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { forwardRef, useEffect } from "react";
import { useState } from "react";
import UserService from "services/users";
import { useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { ROLE_ADMIN, ROLE_CLIENT } from "utils/constant";

const Roles = (props, ref) => {
  const { user, toast, onCancel, setLoading } = props;

  const [detail, setDetail] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user)
      UserService.getUserById({
        params: {
          id: user._id,
        },
      }).then((res: any) => {
        if (res.user) {
          if (res.user.role === "ADMIN") setData(ROLE_ADMIN);
          else setData(ROLE_CLIENT);
          setDetail(res.user);
          setPermissions(res.user.permissions || {});
        }
      });
  }, [user]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submitHandler();
    },
  }));

  const handleSelect = (e: any, p: string) => {
    setPermissions({
      ...permissions,
      [p]: e.checked,
    });
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      await UserService.updateUser({
        params: {
          id: detail._id,
        },
        body: {
          ...detail,
          permissions,
        },
      });
      onCancel();
      showToast(toast, "success", "User save!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div>
      <Card header={<h5>User : {user.email}</h5>}>
        <DataTable
          value={data}
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 100]}
          showGridlines
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            field="action"
            header="Action"
            body={(rowData) => rowData}
          ></Column>
          <Column
            field="access"
            header="Access"
            body={(rowData) => (
              <Checkbox
                inputId="binary"
                checked={permissions && permissions[rowData]}
                onChange={(e) => handleSelect(e, rowData)}
              />
            )}
          ></Column>
        </DataTable>
      </Card>
    </div>
  );
};

export default forwardRef(Roles);
