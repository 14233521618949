/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { selectEmpty, showToast } from "utils/common";
import { forwardRef } from "react";
import MenuService from "services/menus";
import {
  COMMON_STATUS,
  MENU_ROLE,
  MENU_STATUS,
  MENU_TYPE,
  MENU_TYPE_VIEW,
} from "utils/enum";
import { Dropdown, InputNumber } from "primereact";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import VUpload from "components/v-upload";
import VDialog from "components/v-dialog";
import IconBar from "components/v-icon-bar";
import { CATEGORY_GROUP_KEY, ICONS } from "utils/constant";
import { Checkbox } from "primereact/checkbox";
import CategoryFilterDetails from "components/v-filter";
import { Panel } from "primereact/panel";
import { InputSwitch } from "primereact/inputswitch";
import debounce from "lodash.debounce";
import Service from "services/categories";
import { Calendar } from "primereact/calendar";
import { nanoid } from "nanoid";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    treeMenus,
    loadTreeMenus,
    menusCategory,
    setMenusCategory,
    categoryFilters,
    getCategoryFilters,
    parentMenu,
  } = props;

  const emptyData = {
    name: "",
    description: "",
    key: "",
    type: MENU_TYPE.MENU,
    url: "",
    type_view: MENU_TYPE_VIEW.INTERNAL,
    icon: "",
    img_icon: "",
    cover: "",
    parent_id: null,
    category: "",
    position: "",
    order: 0,
    width: "",
    height: "",
    width_scrollbar: "",
    required_token: false,
    status: MENU_STATUS.ENABLE,
    category_filter_id: null,
    is_home_url: false,
    is_hide_on_app: false,
    is_hide_on_web: false,
    sites: null,
    is_coming_soon: false,
    is_pdf: false,
    pdf_file: "",

    coming_soon: {
      user_id: null,
      is_facebook: false,
      is_youtube: false,
      is_images: false,
      facebook: "",
      youtube: "",
      positions: [],
      images: [],
      start_date: new Date(),
      end_date: new Date(),
      countdown: "",
      order: 0,
      video: "",
      name: "",
      action_href: [],
      category_filter_id: null,
      description: "",
      status: COMMON_STATUS.ENABLE,
      adv_users: [],
      resolution: {
        x: 0,
        y: 0,
      },
      marquee: {
        fileUrl: "",
        content: "",
        background_color: "",
        text_color: "",
      },
      link_video: "",
      link_href_video: "",
      time_start_show: 0,
      time_end_show: 0,
      enable_close: false,
      time_enable_close: 0,
      popupPosition: "",
      menus: [],
      background_countdown: "",
    },
  };

  const [details, setDetails] = useState(emptyData);
  const refDialogDetail = useRef(null);
  const refDetailCategory = useRef(null);
  const icons = ICONS.KEYS.map((i) => ICONS.PREFIX + i + ICONS.SIZE);
  const [treeMenusByGroup, setTreeMenusByGroup] = useState(treeMenus);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails({
        ...details,
        ...data,
      });
      loadTreeMenusForPosition(data?.position);
    }
  }, [data]);

  useEffect(() => {
    if (details?.position) {
      loadTreeMenusForPosition(details?.position);
    }
  }, [details?.position]);

  const loadTreeMenusForPosition = (position) => {
    MenuService.filterTreeMenu({
      role: MENU_ROLE.USER,
      position: position,
    }).then((res: any) => {
      if (res) {
        setTreeMenusByGroup(res);
      }
    });
  };

  const onChange = (name, value, field?) => {
    if (field) {
      setDetails((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [name]: value === "-1" ? null : value,
        },
      }));
    }
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value === "-1" ? null : value,
    }));
  };

  const handleImageChange = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      coming_soon: {
        ...state.coming_soon,
        images: state.coming_soon.images.map((item) =>
          item.id === index ? { ...item, src: e } : item
        ),
      },
    }));
  };

  const changeLinkItemImages = (e: any, index: string) => {
    setDetails((state) => ({
      ...state,
      coming_soon: {
        ...state.coming_soon,
        images: state.coming_soon.images.map((item) =>
          item.id === index ? { ...item, link: e.target.value } : item
        ),
      },
    }));
  };

  const moveUp = (index: number) => {
    const _images = details.coming_soon.images;
    const _before = details.coming_soon.images[index - 1];
    const _after = details.coming_soon.images[index];
    _images[index] = _before;
    _images[index - 1] = _after;
    setDetails((state) => ({
      ...state,
      coming_soon: {
        ...state.coming_soon,
        images: [..._images],
      },
    }));
  };

  const moveDown = (index: number) => {
    const _images = details.coming_soon.images;
    const _before = details.coming_soon.images[index + 1];
    const _after = details.coming_soon.images[index];
    _images[index] = _before;
    _images[index + 1] = _after;
    setDetails((state) => ({
      ...state,
      coming_soon: {
        ...state.coming_soon,
        images: [..._images],
      },
    }));
  };

  const handleSubIndex = (index) => {
    setDetails((state) => ({
      ...state,
      coming_soon: {
        ...state.coming_soon,
        images: state.coming_soon.images.filter((i) => i.id !== index),
      },
    }));
  };

  const handleAdd = () => {
    const added = [
      ...details.coming_soon.images,
      { id: nanoid(), src: "", link: "" },
    ];
    setDetails((state) => ({
      ...state,
      coming_soon: { ...state.coming_soon, images: added },
    }));
  };

  const onFilterCategory = async (e) => {
    if (e?.filter) {
      const res: any = await Service.search({
        query: {
          group: CATEGORY_GROUP_KEY.MENU,
          search: e?.filter,
          page: 1,
          limit: 10,
        },
      });
      if (res && res?.docs) {
        const newData = res?.docs?.filter(
          (el) => !menusCategory.find((item) => item?._id === el?._id)
        );
        setMenusCategory((menusCategory || []).concat(newData));
      }
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data?._id?.length > 0) {
        await MenuService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await MenuService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      loadTreeMenus();
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  //CREATE OR UPDATE
  const openNew = () => {
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSelectIcon = (e) => {
    setDetails({ ...details, icon: e });
    onCancelDialogDetail();
  };

  const onSearchFilterPosition = debounce(onFilterCategory, 700);

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Menu Category</label>
        {/* <InputText
          id="position"
          value={details.position}
          onChange={(e) => onChange("position", e.target.value)}
          required
        /> */}
        <Dropdown
          filter
          value={details.position}
          options={menusCategory}
          optionLabel="name"
          optionValue="key"
          onFilter={(e) => onSearchFilterPosition(e)}
          onChange={(e) => onChange("position", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key (Language)</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Description</label>
        <InputText
          id="description"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(MENU_TYPE).map((k) => ({
            label: k,
            value: MENU_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type View</label>
        <Dropdown
          value={details.type_view}
          options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
            label: k,
            value: MENU_TYPE_VIEW[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type_view", e.value)}
        />
      </div>
      {details.type_view === MENU_TYPE_VIEW.WEBVIEW && (
        <>
          <div className="field col-12">
            <label htmlFor="key">Required</label>
            <div className="ml-2">
              <Checkbox
                onChange={(e) => onChange("required_token", e?.checked)}
                checked={details.required_token}
              ></Checkbox>
            </div>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Width</label>
            <InputText
              id="description"
              value={details.width}
              placeholder={"pixel or %"}
              onChange={(e) => onChange("width", e.target.value)}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Height</label>
            <InputText
              id="description"
              value={details.height}
              placeholder={"pixel or %"}
              onChange={(e) => onChange("height", e.target.value)}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Width ScrollBar</label>
            <InputText
              id="description"
              value={details.width_scrollbar}
              placeholder={"pixel or %"}
              onChange={(e) => onChange("width_scrollbar", e.target.value)}
              required
            />
          </div>
        </>
      )}
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>

      {/* <div className="field col-12 md:col-6">
        <label htmlFor="name">Icon</label>
        <InputText
          id="icon"
          value={details.icon}
          onChange={(e) => onChange("icon", e.target.value)}
          required
        />
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Icon</label>
        <div>
          <div>
            {details.icon ? (
              <Button icon={details.icon || ""} disabled visible />
            ) : (
              <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
            )}{" "}
            &nbsp;&nbsp;
            <>
              {!details.icon ? (
                <Button icon="pi pi-plus" onClick={openNew} />
              ) : (
                <Button
                  icon="pi pi-times"
                  className="p-button-sm p-button-danger"
                  aria-label="Delete"
                  style={{
                    height: "38px",
                    marginLeft: "3px",
                  }}
                  onClick={(_) => {
                    if (details.icon) onChange("icon", null);
                  }}
                />
              )}
              <VUpload
                urlFile={details.img_icon}
                setUrlFile={(file) => onChange("img_icon", file)}
              />
            </>
          </div>
        </div>
      </div>

      <VDialog ref={refDialogDetail} header="Select icon">
        <IconBar selected={onSelectIcon} icons={icons}></IconBar>
      </VDialog>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Cover</label>
        <div>
          <div>
            <>
              <VUpload
                urlFile={details.cover}
                setUrlFile={(file) => onChange("cover", file)}
              />
            </>
          </div>
        </div>
      </div>
      <div className="field col-3 md:col-2">
        <label htmlFor="is_home_url">Home Url</label>
        <br />
        <InputSwitch
          checked={details.is_home_url}
          onChange={(e) => onChange("is_home_url", e.value)}
        />
      </div>
      <div className="field col-3 md:col-2">
        <label htmlFor="is_hide_on_app">Hide on app</label>
        <br />
        <InputSwitch
          checked={details.is_hide_on_app}
          onChange={(e) => onChange("is_hide_on_app", e.value)}
        />
      </div>

      <div className="field col-3 md:col-2">
        <label htmlFor="is_hide_on_web">Hide on web</label>
        <br />
        <InputSwitch
          checked={details.is_hide_on_web}
          onChange={(e) => onChange("is_hide_on_web", e.value)}
        />
      </div>

      <div className="field col-3 md:col-2">
        <label>Is Coming Soon</label>
        <br />
        <InputSwitch
          checked={details.is_coming_soon}
          onChange={(e) => onChange("is_coming_soon", e.target.value)}
        />
      </div>
      <>
        {details.is_coming_soon && (
          <div className="field col-12">
            <Panel
              header="Coming soon"
              toggleable
              className="my-2"
              collapsed={true}
            >
              <div className="field col-12 md:col-6">
                <label htmlFor="countdown">Countdown</label>
                <span className="p-float-label">
                  <Calendar
                    className="w-full"
                    id="time24"
                    value={
                      !!details.coming_soon?.countdown
                        ? new Date(details.coming_soon?.countdown)
                        : undefined
                    }
                    dateFormat="mm-dd-yy"
                    onChange={(e: any) =>
                      onChange("countdown", e.value, "coming_soon")
                    }
                    showTime
                    showSeconds
                    showButtonBar
                  />
                </span>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="background_countdown">
                  Background countdown
                </label>
                <div className="col-12">
                  <VUpload
                    urlFile={details.coming_soon?.background_countdown}
                    setUrlFile={(url) =>
                      onChange("background_countdown", url, "coming_soon")
                    }
                  />
                </div>
              </div>
              <h4>Video</h4>
              <div className="field col-12 md:col-6">
                <div className="col-12">
                  <VUpload
                    fileType="video"
                    urlFile={details.coming_soon?.video}
                    setUrlFile={(url) => onChange("video", url, "coming_soon")}
                  />
                </div>
              </div>
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="name"
                    className="w-full"
                    value={details.coming_soon?.link_href_video}
                    onChange={(e) =>
                      onChange("link_href_video", e.target.value, "coming_soon")
                    }
                  />
                  <label htmlFor="id">Link href</label>
                </span>
              </div>
              <div className="pb-3">
                <h4>Images</h4>
              </div>
              {details.coming_soon?.images.map((item, index) => {
                return (
                  <div className="grid" key={item.id}>
                    <div className="col-12 md:col-5">
                      <VUpload
                        urlFile={item.src}
                        setUrlFile={(e) => handleImageChange(e, item.id)}
                      />
                      <span className="p-float-label">
                        <InputText
                          id="name"
                          className="w-full"
                          value={item.link}
                          onChange={(e) => changeLinkItemImages(e, item.id)}
                        />
                        <label htmlFor="link">Link</label>
                      </span>
                    </div>

                    <div className="flex field col-12 md:col-2 md:justify-content-evenly">
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        aria-label="Cancel"
                        onClick={(_) => handleSubIndex(item.id)}
                      />
                      {index === 0 ? (
                        <Button
                          icon="bx bx-chevron-down"
                          className="p-button-rounded p-button-warning"
                          aria-label="Cancel"
                          onClick={(_) => moveDown(index)}
                        />
                      ) : index === details.coming_soon?.images.length - 1 ? (
                        <Button
                          icon="bx bx-chevron-up"
                          className="p-button-rounded p-button-primary"
                          aria-label="Cancel"
                          onClick={(_) => moveUp(index)}
                        />
                      ) : (
                        <div>
                          <Button
                            icon="bx bx-chevron-up"
                            className="p-button-rounded p-button-primary"
                            aria-label="Cancel"
                            onClick={(_) => moveUp(index)}
                          />
                          <Button
                            icon="bx bx-chevron-down"
                            className="p-button-rounded p-button-warning"
                            aria-label="Cancel"
                            onClick={(_) => moveDown(index)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <Button
                onClick={handleAdd}
                icon="bx bxs-add-to-queue"
                className="p-button-rounded p-button-success mb-3"
                aria-label="Search"
              />
            </Panel>
          </div>
        )}
      </>

      <div className="field col-3 md:col-2">
        <label>Is PDF</label>
        <br />
        <InputSwitch
          checked={details.is_pdf}
          onChange={(e) => onChange("is_pdf", e.target.value)}
        />
      </div>
      {details.is_pdf && (
        <div className="field col-12">
          <Panel
            header="Upload PDF"
            toggleable
            className="my-2"
            collapsed={true}
          >
            <span className="p-float-label">
              <VUpload
                label="PDF file"
                fileType=".pdf"
                urlFile={details.pdf_file}
                setUrlFile={(file) => {
                  onChange("pdf_file", file);
                }}
              />
            </span>
          </Panel>
        </div>
      )}

      <VDialog ref={refDialogDetail} header="Select icon">
        <IconBar selected={onSelectIcon} icons={icons}></IconBar>
      </VDialog>
      {/* <div className="field col-12 md:col-12">
        <label htmlFor="description">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={menus.map((m) => ({
            label: m.name,
            value: m._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("parent_id", e.value)}
          showClear
          filter
        />
      </div> */}
      <div className="field col-12 md:col-12">
        <label htmlFor="description">Parent</label>
        {parentMenu?.id?.length > 0 ? (
          <Dropdown
            value={details.parent_id}
            options={[parentMenu]}
            optionLabel="name"
            optionValue="id"
            disable={true}
          />
        ) : (
          <TreeSelect
            placeholder="Select Parent Menu"
            inputId="treeselect"
            value={details.parent_id}
            onChange={(e) => onChange("parent_id", e.value)}
            options={[selectEmpty, ...treeMenusByGroup]}
          />
        )}
      </div>

      <div className="field col-12 md:col-12">
        <label htmlFor="name">
          URL{" "}
          {details.type_view === MENU_TYPE_VIEW.WEBVIEW && "(token={token})"}
        </label>
        <InputText
          id="url"
          value={details.url}
          onChange={(e) => onChange("url", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Order</label>
        <InputNumber
          id="name"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(MENU_STATUS).map((k) => ({
            label: k,
            value: MENU_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
      <div></div>
      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
