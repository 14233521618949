/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact";
import { ROLE_CLIENT } from "utils/constant";
import VipService from "services/vips";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    key: "",
    price: 0,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await VipService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await VipService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Key</label>
        <Dropdown
          defaultValue={details.key}
          value={details.key}
          options={ROLE_CLIENT.map((r) => ({
            label: r,
            value: r,
          }))}
          onChange={(e) => onChange("key", e.value)}
          optionLabel="label"
          placeholder="Select"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Price</label>
        <InputNumber
          onChange={(e) => onChange("price", e.value)}
          value={details.price}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
