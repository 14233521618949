import { request, parseErrorResponse } from "./request";
import qs from "query-string";
import { IRequestData } from "./s3-request";

const search = (req: { query: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/products/search/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProductsDonate = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/products/getProductsDonate/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllProduct = (req: { query: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/products/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createProducts = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/products`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteProducts = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/products/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProductsById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/products/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCodesByProductId = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/products/code/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateProducts = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/products/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const importExcel = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/products/import-excel", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const importCode = (req: { params: { id: any }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/products/import-code/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

// digitals
const searchDigital = (req: { query: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/products/search/digital/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateDigital = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/products/updateDigital/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteDigital = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/products/deteleDigital/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const approvedDigital = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/products/approvedDigital/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAndConvertWrongProducts = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/products/convert/wrong-text-search`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ProductService = {
  getAllProduct,
  createProducts,
  deleteProducts,
  getProductsById,
  updateProducts,
  importExcel,
  getCodesByProductId,
  importCode,
  search,
  // digital
  searchDigital,
  updateDigital,
  deleteDigital,
  approvedDigital,
  getProductsDonate,

  getAndConvertWrongProducts,
};

export default ProductService;
