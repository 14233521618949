/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact";
import "../styles.scss";
import { LEVEL_STATUS, PARTICIPANT_STATUS } from "utils/enum";
import CampaignService from "services/campaigns";
import { Image } from "primereact/image";

const Details = (props, ref) => {
  const { data, reload, toast, setLoading } = props;

  const emptyData: any = {
    user: {},
    user_id: "",
    status: PARTICIPANT_STATUS.COMPLETED,
    data: {},
    campaign_attachment: {
      status: LEVEL_STATUS.PENDING,
      uploads: [],
      approved: [],
    },
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      if (data) {
        const { user_id, object_id, campaign_attachment } = details;
        await CampaignService.approvedFile({
          body: {
            status: campaign_attachment.status,
            userId: user_id,
            campaignId: object_id,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      console.log({
        error,
      });
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 ">
            <label htmlFor="user">Email</label>
            <InputText id="name" value={details.user.email} disabled />
          </div>
          <div className="field col-12 ">
            <label htmlFor="user">Full name</label>
            <InputText id="name" value={details.user.full_name} disabled />
          </div>

          <div className="field col-12 ">
            <label htmlFor="status">Status level</label>
            <Dropdown
              value={details.campaign_attachment.status}
              options={Object.keys(LEVEL_STATUS).map((k) => ({
                label: k,
                value: LEVEL_STATUS[k],
              }))}
              onChange={(e) =>
                onChange(e.value, "status", "campaign_attachment")
              }
              optionLabel="label"
              placeholder="Select status"
            />
          </div>
        </div>
      </Panel>

      <Panel header="Uploads" toggleable className="my-2" collapsed={false}>
        <div className="grid">
          {details.campaign_attachment.uploads.map((f, key) => (
            <Image key={key} src={getUrlImage(f.src)} preview width="70" className="ml-3" />
          ))}
        </div>
      </Panel>

      <Panel header="Approved" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          {details.campaign_attachment.approved.map((f) => (
            <Image src={getUrlImage(f.src)} preview width="70" />
          ))}
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(Details);
