/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import WebModulesIframe from "components/iframe-web-module";
import "./styles.scss";

const View = () => {
  const [loading, setLoading] = useState(false);

  const getIframeUrl = (key: string) => {
    const pathKey = {
      posts_ads: "/advertisement",
    };
    if (pathKey[key]) {
      return `${process.env.REACT_APP_WEB_MODULE_URL}${
        pathKey[key] || "/"
      }?origin=${
        window.origin
      }&vdb_token=${localStorage.getItem(
        "token_auth"
      )}&lang=en`;
    }
    return null;
  };

  const onLoadIframeOk = () => {
    setLoading(false);
  };

  useEffect(() => {
    const setHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    window.addEventListener("resize", setHeight);
    setHeight();

    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  return (
    <div className="posts-ads-wrapper">
      <WebModulesIframe
      url={getIframeUrl("posts_ads")}
      className="iframe"
      title="web modules modal"
      onLoad={() => onLoadIframeOk()}
      loading={loading}
    />
    </div>
  );
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
