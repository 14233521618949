import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import { useContext } from "react";
import { MasterContext } from "contexts/MasterContext";

const AppSubmenu = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { notifyPending } = useContext(MasterContext);
  const location = useLocation();

  const onMenuItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any,
    index: React.SetStateAction<null>
  ) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const onKeyDown = (event: any) => {
    if (event.code === "Enter" || event.code === "Space") {
      event.preventDefault();
      event.target.click();
    }
  };

  const renderLinkContent = (item: any) => {
    let submenuIcon = item.children && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );
    return (
      <React.Fragment>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        {renderBadge(item.module, item.label)}
        <Ripple />
      </React.Fragment>
    );
  };

  const renderBadge = (module, label) => {
    let amount = 0;
    if (module === "payments" && label === "Payments") {
      amount = notifyPending.payment_withdraw + notifyPending.payment_deposit;
    }
    if (module === "transactions" && label === "Transactions") {
      amount =
        notifyPending.transaction_withdraw + notifyPending.transaction_deposit;
    }
    if (module === "kycs" && label === "Kycs & Verifies") {
      amount = notifyPending.pending_kyc + notifyPending.pending_verified;
    }

    if (module === "payments") {
      if (label === "Withdraws") {
        amount = notifyPending.payment_withdraw;
      }
      if (label === "Deposits") {
        amount = notifyPending.payment_deposit;
      }
    }
    if (module === "transactions") {
      if (label === "Withdraws") {
        amount = notifyPending.transaction_withdraw;
      }
      if (label === "Deposits") {
        amount = notifyPending.transaction_deposit;
      }
    }
    if (module === "kycs") {
      if (label === "Kycs") {
        amount = notifyPending.pending_kyc;
      }
      if (label === "Verifies") {
        amount = notifyPending.pending_verified;
      }
    }
    return amount > 0 ? <Badge value={amount} /> : <></>;
  };

  const isParentOfActiveItem = (item) => {
    let checkPath = location.pathname;
    if (item.to && checkPath === item.to) {
      return true;
    }
    if (item.children && item.children.length > 0) {
      let checkChild = false;
      item.children.forEach((element) => {
        if (isParentOfActiveItem(element)) {
          checkChild = true;
        }
      });
      return checkChild;
    }
    return false;
  };

  const renderLink = (item: any, i: any, isRoot: boolean) => {
    let content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          aria-label={item.label}
          onKeyDown={onKeyDown}
          role="menuitem"
          className={isRoot ? "layout-menuitem-root-text" : "p-ripple"}
          activeClassName="router-link-active router-link-exact-active active"
          to={item.to}
          onClick={(e) => onMenuItemClick(e, item, i)}
          exact
          target={item.target}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          tabIndex={0}
          aria-label={item.label}
          onKeyDown={onKeyDown}
          role="menuitem"
          href={item.url}
          className={isRoot ? "layout-menuitem-root-text" : "p-ripple"}
          onClick={(e) => onMenuItemClick(e, item, i)}
          target={item.target}
        >
          {content}
        </a>
      );
    }
  };

  let items =
    props.items &&
    props.items.map((item: any, i: React.Key | null | undefined) => {
      let active = activeIndex === i || isParentOfActiveItem(item);
      let styleClass = classNames(item.badgeStyleClass, {
        "layout-menuitem-category": props.root,
        "active-menuitem": active && !item.to,
      });

      // if (props.root) {
      //   return (
      //     <li className={styleClass} key={i} role="none">
      //       {props.root === true && (
      //         <React.Fragment>
      //           <div
      //             className="layout-menuitem-root-text"
      //             // aria-label={item.label}
      //           >
      //             {item.label}
      //           </div>
      //           <AppSubmenu
      //             items={item.items}
      //             onMenuItemClick={props.onMenuItemClick}
      //           />
      //         </React.Fragment>
      //       )}
      //     </li>
      //   );
      // } else {
      return (
        <li className={styleClass} key={i} role="none">
          {renderLink(item, i, props.root === true)}
          {/* {props.root === true ? (
            <AppSubmenu
              items={item.items}
              onMenuItemClick={props.onMenuItemClick}
            />
          ) : ( */}
          <CSSTransition
            classNames="layout-submenu-wrapper"
            timeout={{ enter: 1000, exit: 450 }}
            in={active}
            unmountOnExit
          >
            <AppSubmenu
              items={item.children}
              onMenuItemClick={props.onMenuItemClick}
            />
          </CSSTransition>
          {/* )} */}
        </li>
      );
      // }
    });

  return items ? (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  ) : null;
};

export const AppMenu = (props: {
  model: any;
  onMenuItemClick: any;
  layoutColorMode: string;
}) => {

  const filterMenu = () => {
    return props.model;
  };

  const menu = filterMenu();

  return (
    <div className="layout-menu-container">
      <AppSubmenu
        items={menu}
        className="layout-menu"
        onMenuItemClick={props.onMenuItemClick}
        root={true}
        role="menu"
      />
    </div>
  );
};
