import { request, parseErrorResponse } from "./request";

const getByUser = (req: { params: { user_id: string } }): Promise<any[]> =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/branches-shop/${req.params.user_id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const branchShopService = {
  getByUser,
};

export default branchShopService;
