import { nanoid } from "nanoid";
import { Button, InputText, Toast } from "primereact";
import { ColorPicker } from "primereact/colorpicker";

import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import VUpload from "components/v-upload";
import { InputSwitch } from "primereact/inputswitch";
import { OBJECT_TYPE } from "utils/enum";

const View = () => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "objects",
      },
    });
    if (res && res.setting) {
      setState(res.setting.value);
    }
  };

  const handleChange = (index, name, value) => {
    const dataValue = value;
    setState((state) => {
      return state.map((item) =>
        item.id === index
          ? {
              ...item,
              [name]: dataValue,
            }
          : item
      );
    });
  };

  const handleAdd = () => {
    setState((state) => [...state, { id: nanoid(8) }]);
  };

  const handleSub = (id) => {
    setState((state) => state.filter((s) => s.id !== id));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await SettingService.updateSetting({
        body: {
          name: "objects",
          value: state,
        },
      });
      setLoading(false);
      getData();
      showToast(toast, "success", "Update success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Objects</h4>
          </div>
          {state?.map((item, index) => {
            return (
              <div key={index} className="grid card my-4">
                <div className="field col-6 md:col-3">
                  <span className="p-float-label">
                    <InputText
                      id="key"
                      className="w-full"
                      value={item.key}
                      onChange={(e) =>
                        handleChange(item.id, "key", e.target.value)
                      }
                    />
                    <label htmlFor="name">Key</label>
                  </span>
                </div>

                <div className="field col-6 md:col-3">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.name}
                      onChange={(e) =>
                        handleChange(item.id, "name", e.target.value)
                      }
                    />
                    <label htmlFor="name">Name</label>
                  </span>
                </div>

                <div className="field col-6 md:col-3">
                  <span className="p-float-label">
                    <InputText
                      id="position"
                      className="w-full"
                      value={item.position}
                      onChange={(e) =>
                        handleChange(item.id, "position", e.target.value)
                      }
                    />
                    <label htmlFor="name">Position</label>
                  </span>
                </div>

                <div className="field col-6 md:col-3">
                  <span className="p-float-label">
                    <div className="flex align-items-center row">
                      <div className="w-100">
                        <InputText
                          id="text_color"
                          className="w-full"
                          value={item.text_color}
                          onChange={(e) =>
                            handleChange(item.id, "text_color", e.target.value)
                          }
                        />
                      </div>
                      <div className="ml-2">
                        <ColorPicker
                          value={item.text_color}
                          onChange={(e) =>
                            handleChange(item.id, "text_color", e.value)
                          }
                        />
                      </div>
                    </div>
                  </span>
                </div>

                <div className="field col-12 md:col-2">
                  <InputSwitch
                    checked={item.is_show}
                    onChange={(e) => handleChange(item.id, "is_show", e.value)}
                  />
                </div>
                <div className="grid col-12">
                  <div className="field col-6 md:col-4 text-center">
                    <label htmlFor="name">Logo Kyc</label>
                    <VUpload
                      urlFile={item.logo_verify}
                      setUrlFile={(file) =>
                        handleChange(item.id, "logo_verify", file)
                      }
                    />
                  </div>
                  <div className="field col-6 md:col-4 text-center">
                    <label htmlFor="name">Logo verify</label>
                    <VUpload
                      urlFile={item.logo_verify_lv_2}
                      setUrlFile={(file) =>
                        handleChange(item.id, "logo_verify_lv_2", file)
                      }
                    />
                  </div>
                  <div className="field col-6 md:col-4 text-center">
                    <label htmlFor="name">Logo</label>
                    <VUpload
                      urlFile={item.logo}
                      setUrlFile={(file) => handleChange(item.id, "logo", file)}
                    />
                  </div>
                  <div className="field col-6 md:col-4 text-center">
                    <label htmlFor="name">Avatar</label>
                    <VUpload
                      urlFile={item.avatar}
                      setUrlFile={(file) =>
                        handleChange(item.id, "avatar", file)
                      }
                    />
                  </div>
                  <div className="field col-6 md:col-4 text-center">
                    <label htmlFor="name">Cover</label>
                    <VUpload
                      urlFile={item.cover}
                      setUrlFile={(file) =>
                        handleChange(item.id, "cover", file)
                      }
                    />
                  </div>
                  <div className="field col-6 md:col-4 text-center">
                    <label htmlFor="icon_maps">Icon maps</label>
                    <VUpload
                      urlFile={item.icon_maps}
                      setUrlFile={(file) =>
                        handleChange(item.id, "icon_maps", file)
                      }
                    />
                  </div>

                  {(item.key === OBJECT_TYPE.FAMILY ||
                    item.key === OBJECT_TYPE.GENEALOGY) && (
                      <div className="field col-6 md:col-4 text-center">
                        <label htmlFor="icon_maps">Background</label>
                        <VUpload
                          urlFile={item.background}
                          setUrlFile={(file) =>
                            handleChange(item.id, "background", file)
                          }
                        />
                      </div>
                    )}
                </div>

                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSub(item.id)}
                  />
                </div>
              </div>
            );
          })}
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
