/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { forwardRef, useContext, useEffect } from "react";
import { useState } from "react";
import UserService from "services/users";
import { useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { MasterContext } from "contexts/MasterContext";
import { ROLE } from "utils/enum";
import { ROLE_CLIENT } from "utils/constant";

const Roles = (props, ref) => {
  const { roles } = useContext(MasterContext);
  const { user, toast, onCancel, setLoading } = props;

  const [detail, setDetail] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState([]);
  const [role, setRole] = useState(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submitHandler();
    },
  }));

  useEffect(() => {
    if (user)
      UserService.getUserById({
        params: {
          id: user._id,
        },
      }).then((res: any) => {
        if (res.user) {
          if (res.user.role === ROLE.USER) {
            setData(ROLE_CLIENT);
          } else {
            getRoles(res.user);
          }
          setRole(res.user.role);
          setPermissions(res.user.permissions || {});
          setDetail(res.user);
        }
      });
  }, [user]);

  const getRoles = (user) => {
    if (user.role === ROLE.SUB_ADMIN) {
      setData(roles[ROLE.SUB_ADMIN]?.permissions || []);
    }
    if (user.role === ROLE.USER) {
      setData(roles[ROLE.USER]?.permissions || []);
    }
  };

  const handleSelect = (e: any, p: string) => {
    setPermissions({
      ...permissions,
      [p]: e.checked,
    });
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      await UserService.updateUser({
        params: {
          id: detail._id,
        },
        body: {
          ...detail,
          permissions,
        },
      });
      onCancel();
      showToast(toast, "success", "User save!");
      setLoading(false);
    } catch (error) {
      console.log({
        error,
      });

      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div>
      {role === ROLE.USER ? (
        <Card header={<h5>User : {user.email}</h5>}>
          <DataTable
            value={data}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            showGridlines
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column
              field="action"
              header="Action"
              body={(rowData) => rowData}
            ></Column>
            <Column
              field="access"
              header="Access"
              body={(rowData) => (
                <Checkbox
                  inputId="binary"
                  checked={permissions && permissions[rowData]}
                  onChange={(e) => handleSelect(e, rowData)}
                />
              )}
            ></Column>
          </DataTable>
        </Card>
      ) : (
        <Card header={<h5>User : {user.email}</h5>}>
          <DataTable
            value={data}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            showGridlines
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column
              field="name"
              header="Name"
              // body={(rowData) => rowData.name}
            ></Column>
            <Column
              field="key"
              header="Permission"
              // body={(rowData) => rowData}
            ></Column>
            <Column
              field="create"
              header="Create"
              body={(rowData) => (
                <Checkbox
                  inputId="binary"
                  checked={
                    permissions && permissions[`${rowData.key}.${"create"}`]
                  }
                  onChange={(e) =>
                    handleSelect(e, `${rowData.key}.${"create"}`)
                  }
                />
              )}
            ></Column>
            <Column
              field="read"
              header="Read"
              body={(rowData) => (
                <Checkbox
                  inputId="binary"
                  checked={
                    permissions && permissions[`${rowData.key}.${"read"}`]
                  }
                  onChange={(e) => handleSelect(e, `${rowData.key}.${"read"}`)}
                />
              )}
            ></Column>
            <Column
              field="update"
              header="Update"
              body={(rowData) => (
                <Checkbox
                  inputId="binary"
                  checked={
                    permissions && permissions[`${rowData.key}.${"update"}`]
                  }
                  onChange={(e) =>
                    handleSelect(e, `${rowData.key}.${"update"}`)
                  }
                />
              )}
            ></Column>
            <Column
              field="delete"
              header="Delete"
              body={(rowData) => (
                <Checkbox
                  inputId="binary"
                  checked={
                    permissions && permissions[`${rowData.key}.${"delete"}`]
                  }
                  onChange={(e) =>
                    handleSelect(e, `${rowData.key}.${"delete"}`)
                  }
                />
              )}
            ></Column>
          </DataTable>
        </Card>
      )}
    </div>
  );
};

export default forwardRef(Roles);
